var tabs = []
var drivers = [
  'privat', 'cbr', 'nbu', 'bank_ua', 'cbar', 'nbp', 'bnm', 'kazahstan', 'turkish', 'nbr',
  'amadeus_ua', 'amadeus_ua_eur', 'amadeus_ru', 'amadeus_de', 'amadeus_us', 'amadeus_direct_usd', 'amadeus_direct_eur',
  'banxico', 'nbkr', 'cba', 'cbi', 'nbz', 'mastercard', 'nbg', 'nab', 'bankofcanada', 'snb', 'boi', 'riksbank', 'boc',
  'cbk', 'cbn', 'sabre_ua', 'sabre_ru', 'sabre_de', 'sabre_us', 'google_usd'
]
for (let i in drivers) {
  let driver = drivers[i]
  tabs.push({
    name: driver,
    items: [
      {
        name: `rate_${driver}`,
        endpoint: '/persistance/general/xrates',
        type: 'graphql',
        lazy: true,
        layouts: {
          table: 'xrates/rates/table.vue',
          panel: 'xrates/rates/panel.vue'
        },
        graphql: {
          name: 'xrateRate',
          queries: {
            index: {
              id: true,
              sourceName: true,
              sourceCurrency: true,
              currency: true,
              rate: true,
              sourceRate: true,
              deviation: true,
              crossRate: true,
              locked: true,
              updatedAt: true
            }
          },
        },
        stores: {
          drivers: drivers
        },
        table: {
          items: [
            {
              name: 'id',
              width: '80px'
            },
            'sourceName',
            'sourceCurrency',
            'currency',
            'rate',
            'sourceRate',
            'deviation',
            'crossRate',
            {
              name: 'locked',
              type: 'checkbox'
            },
            {
              name: 'updatedAt',
              format: 'date_time'
            },
            {
              name: 'actions',
              width: '70px',
              align: 'center'
            }
          ]
        },
        history: [
          'TtnXratesService::Rate'
        ],
        actions: {
          row: [
            {
              name: 'update',
              icon: 'el-icon-edit',
              type: 'primary',
              plain: true
            }
          ],
          panel: [
            {
              name: 'refresh',
              icon: 'el-icon-refresh',
              type: 'primary',
              plain: true
            },
            {
              name: 'history',
              icon: 'el-icon-refresh',
              type: 'warning',
              plain: true
            },
            {
              name: 'filter',
              context: 'panel',
              icon: 'el-icon-help',
              plain: true
            }
          ]
        },
        filter: {
          fieldsets: [
            {
              legend: 'default',
              items: [
                {
                  name: 'code',
                  type: 'hidden',
                  operator: 'eq',
                  scope: 'sources',
                  default: driver
                },
                {
                  name: 'currency',
                  type: 'text',
                  operator: 'eq',
                  scope: 'rates'
                },
                {
                  name: 'locked',
                  type: 'radiogroup',
                  button: true,
                  operator: 'eq',
                  scope: 'rates',
                  store: {
                    '': 'Any',
                    true: 'yes',
                    false: 'no'
                  },
                }
              ]
            }
          ]
        },
        form: {
          actualize: true,
          fieldsets: [
            {
              legend: 'Default',
              items: [
                {
                  name: 'id',
                  type: 'hidden',
                  required: false,
                  valueType: 'integer'
                },
                {
                  name: 'rate',
                  type: 'text',
                  valueType: 'float'
                },
                {
                  name: 'locked',
                  type: 'checkbox'
                }
              ]
            }
          ]
        }
      },

      {
        name: `rate_history_${driver}`,
        lazy: true,
        endpoint: '/persistance/general/xrates',
        type: 'graphql',
        graphql: {
          name: 'xrateRateHistory',
          queries: {
            index: {
              id: true,
              sourceName: true,
              rates: {
                currency: true,
                rate: true,
                delta: true,
                crossRate: true
              },
              updatedAt: true
            }
          },
          actions: {
            index: 'xrateRateHistorys'
          }
        },
        table: {
          items: [
            {
              name: 'id',
              width: '80px'
            },
            'sourceName',
            {
              name: 'updatedAt',
              format: 'date_time'
            },
            'expand'
          ]
        },
        history: [
          'TtnXratesService::Rate'
        ],
        actions: {
          row: [],
          panel: [
            {
              name: 'refresh',
              icon: 'el-icon-refresh',
              type: 'primary',
              plain: true
            },
            {
              name: 'filter',
              context: 'panel',
              icon: 'el-icon-help',
              plain: true
            }
          ]
        },
        filter: {
          fieldsets: [
            {
              legend: 'default',
              items: [
                {
                  name: 'code',
                  type: 'hidden',
                  operator: 'eq',
                  scope: 'sources',
                  default: driver
                },
                {
                  name: 'updated_at',
                  operator: 'btw',
                  type: 'daterange',
                  required: false,
                  options: {
                    firstDayOfWeek: 1
                  },
                  valueFormat: 'yyyy-MM-dd',
                  scope: 'rateHistories'
                }
              ]
            }
          ]
        },
        form: {},
        combined: [
          {
            name: `rate_history_combined_${driver}`,
            boot: {
              type: 'local',
              row_key: 'rates',
            },
            table: {
              items: [
                {
                  name: 'currency',
                  width: '80px'
                },
                'rate',
                'delta',
                'crossRate'
              ]
            },
          }
        ]
      }
    ]
  })
}
export default tabs
