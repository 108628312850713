<template lang="pug">
  extends /table
  block custom
    currencies-dialog
</template>

<script>
import Base from '@crud_view/table'
import currenciesDialog from './currencies'

export default {
  extends: Base,
  components: {
    currenciesDialog
  },
  methods: {
    async actionCurrencies(row) {
      this.$emit('showCurrenciesDialog', row)
    }
  }
}
</script>