import { loadCrudModules } from '@/utils'

export default {
  name: 'xrates',
  path: '/xrates',
  alwaysShow: true,
  meta: {
    title: 'xrates.default',
    icon: 'chart'
  },
  children: loadCrudModules(require.context('./xrates', true, /\.js$/))
}
