<template lang="pug">
  el-dialog(:fullscreen="false" :title="translate('modal.add_currency')" :visible.sync="visible" :close-on-click-modal="false" width="30%" :custom-class="'dialog-edit-form'")
    el-form(ref="dataForm" :model="temp" :rules="rules")
      fieldset(class="el-form-fieldset")
        el-form-item(label="Select currency" prop="currency_id")
          el-select(v-model="temp['currency_id']" clearable filterable)
            el-option(
              v-for="item in currencyOptions"
              :key="item.isoCode"
              :label="item.isoCode"
              :value="item.id"
            )
    div(slot="footer" class="dialog-footer")
      el-button(@click="visible = false") {{ translate('form.cancel') }}
      el-button(type="primary" @click="createRelation()") {{ translate('form.confirm') }}
</template>

<script>
import common from '@crud_lib/common'
import di from '@crud_lib/di'

export default {
  mixins: [common, di],
  data() {
    return {
      visible: false,
      temp: {
        sourse_id: 0,
        currency_id: null
      },
      rules: {
        currency_id: [
          { required: true, trigger: 'blur' }
        ]
      }
    }
  },

  computed: {
    currencyOptions() {
      return this.$store.getters[`${this.storeName}/preload`].currencies ? this.$store.getters[`${this.storeName}/preload`].currencies : []
    }
  },

  mounted() {
    this.$parent.$on('showCurrenciesDialog', (row) => {
      this.temp = {
        source_id: row.id,
        currency_id: null
      }
      this.visible = true
    })
  },

  beforeDestroy() {
    this.$parent.$off('showCurrenciesDialog')
  },

  methods: {

    async createRelation() {
      this.$refs['dataForm'].validate(async (valid) => {
        if (valid) {
          try {
            const query = {
              __args: {
                sourceId: parseInt(this.temp.source_id),
                currencyId: parseInt(this.temp.currency_id),
              },
              id: true
            }

            await this.graphql('xrateSourceAddCurrency', query, 'mutation')
            this.$message({
              message: 'Success!',
              type: 'success'
            })
            this.visible = false
            this.$elItem.$emitAction('refresh')
          } catch (e) {
            this.$message({
              message: e,
              type: 'error'
            })
          }
        }
      })
    }

  }
}
</script>
