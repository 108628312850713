export default {
  table:{
    sourceName: 'Driver',
    rate: 'Rate',
    crossRate: 'Cross rate',
    delta: 'Delta',
    currency: 'Currency'
  },
  form: {
    fields: {
      updatedAt: 'Date'
    },
    legends: {
      default: 'Filters'
    }
  }
}
