<template lang="pug">
  extends /table
</template>

<script>
import Base from '@crud_view/table'

export default {
  extends: Base,
  methods: {
    rowClassName({ row, rowIndex }) {
      if (row.locked) {
        return 'row-locked'
      }
    },
  }
}
</script>

<style>
  .row-locked td {background: #ffe1e1 !important}
</style>
