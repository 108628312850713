import source from './source/source'

export default {
  namespace: 'xrates',
  component: 'settings',
  meta: {
    title: 'xrates.settings',
    icon: 'table'
  },
  tabs: [
    source
  ]
}
