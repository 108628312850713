export default {
  data() {
    return {
      filterCollapsed: false,
      filters: {},
      showForm: false,
      formReady: false,
      process: false,
      activeFieldsets: [],
      activeTabValue: 'filters',
      validator: (rule, value, callback) => {
        if (!rule.required) {
          return callback()
        }
        if (
          Array.isArray(value.value) && value.value.length === 0 ||
          !Array.isArray(value.value) && value.value === ''
        ) {
          return callback(new Error('Please input correct value'))
        }
        return callback()
      }
    }
  },
  watch: {
    formReady(val) {
      if (val && this.config.filter.collapsed) {
        this.actionApplyFilter(false)
      }
    }
  },

  computed: {
    classObj() {
      return {
        hideFilter: this.filterCollapsed,
        openFilter: !this.filterCollapsed
      }
    },
    defaultOperators() {
      return this.$parent.defaultOperators
    },

    fieldsets() {
      return this._prepareFieldsets('filter')
    }
  },
  mounted() {
    if (this.config.filter.collapsed || this.fieldsets.length === 0) {
      this.filterCollapsed = true
      this.$elItem.$emit(this.$channels.filterCollapse, this.filterCollapsed)
    }
    this.activeFieldsets = this.fieldsets.filter((i) => !i.collapsed).map((i) => i.legend)
    this.defaultFilter(this.$elItem.config.lazy)
    
    this.$elItem.$on(this.$channels.filterApply, this.actionApplyFilter)
    this.$elItem.$on(this.$channels.filterClear, this.actionClearFilter)
  },

  beforeDestroy() {
    this.$elItem.$off(this.$channels.filterApply, this.actionApplyFilter)
    this.$elItem.$off(this.$channels.filterClear, this.actionClearFilter)
  },

  methods: {
    actionFilter() {
      this.filterCollapsed = !this.filterCollapsed
      this.$elItem.$emit(this.$channels.filterCollapse, this.filterCollapsed)
    },
    actionRefresh() {
      this.isActiveItem() && this.defaultFilter(false)
    },

    actionApplyFilter(resetPage = true, clearParams = false) {
      if ((!this.formReady || this.process) && this.fieldsets.length > 0) {
        return
      }
      this.process = true
      let filters = {}
      const filterValue = {}
      const prefilledData = {}
      let searchFilters = {}
      const searchEl = (this.$elItem.$children && this.$elItem.$children[0])

      this.$nextTick(() => {
        if ( searchEl && searchEl.search && searchEl.search.field && searchEl.search.value ) {
          const fItem = searchEl.searchByFields.find((x) => { return x.name == searchEl.search.field })

          const value = this.formatField(searchEl.search.value, fItem)
          searchFilters[fItem.scope] = searchFilters[fItem.scope] || []
          searchFilters[fItem.scope].push({
            field: fItem.name,
            operator: fItem.operator,
            value: value,
            hidden: fItem.hidden || false
          })

          filters = { ...filters, ...searchFilters }
        }

        if (this.fieldsets.length === 0) {
          this.$elItem.$emit(this.$channels.filterReady, { filters, resetPage, prefilledData, clearParams, filterValue })
          this.process = false
        } else {
          this.$refs.filterForm.validate((valid) => {
            if (valid) {
              for (const i in this.filters) {
                const fItem = this.filters[i]
                if (
                  Array.isArray(fItem.value) && fItem.value.length > 0 ||
                  !Array.isArray(fItem.value) && fItem.value !== ''
                ) {
                  const value = this.formatField(fItem.value, fItem)
                  filters[fItem.scope] = filters[fItem.scope] || []
                  filters[fItem.scope].push({
                    field: fItem.field,
                    operator: fItem.operator,
                    value: value,
                    hidden: fItem.hidden
                  })
                  filterValue[i] = fItem
                  if (fItem.prefilled) {
                    prefilledData[fItem.field] = value
                  }
                }
              }
              this.$elItem.$emit(this.$channels.filterReady, { filters, resetPage, prefilledData, clearParams, filterValue })
            }
            this.process = false
          })
        }
      })
    },

    actionClearFilter() {
      if(this.isActiveItem()) {
        this.$refs.filterForm.resetFields()
        this.defaultFilter(true, true)
      }
    },

    eventFormMounted() {
      this.formReady = true
    },

    async defaultFilter(reset = false, clearParams = false) {
      const filters = {}
      const defaultScope = this.config.graphql ? this.config.graphql.actions.index : ''
      const scopes = {}
      const requiredItems = {}
      await this.fields.forEach(async(i) => {
        filters[i.name] = {
          field: i.name,
          operator: i.operator || 'eq',
          value: i.default !== undefined ? await this.formatDefaultValue(i) : '',
          scope: i.scope || defaultScope,
          groupKey: i.groupKey,
          hidden: (i.hidden || false),
          valueType: i.valueType,
          valueDelimiter: i.valueDelimiter
        }
        if( i.regexpFormat != null ) filters[i.name].regexpFormat = i.regexpFormat
        scopes[i.scope || defaultScope] = true
        requiredItems[i.name] = !!i.required
      })

      let needLoad = false
      let hasFilters = false
      const activeFieldsetKeys = {}

      if (!reset && this.$route.query.filters && this.isActiveItem()) {
        for (const scope in this.$route.query.filters) {
          if (scopes[scope]) {
            for (const f in this.$route.query.filters[scope]) {
              const row = this.$route.query.filters[scope][f]
              if (row.field && filters[row.field]) {
                filters[row.field].value = this.formatFieldReverse(row.value, filters[row.field])
                hasFilters = true
                activeFieldsetKeys[filters[row.field].groupKey] = true
                needLoad = needLoad || !requiredItems[row.field]
                if (
                  filters[row.field].operator === row.operator &&
                  this.defaultOperators.includes(row.operator)
                ) {
                  filters[row.field].operator = row.operator
                }
              }
              if (row.prefilled) {
                filters[row.field].prefilled = true
              }
            }
          }
        }
      }
      this.filters = { ...filters }
      this.showForm = true
      this.$nextTick(async() => {
        if (hasFilters && !needLoad) {
          await this.$refs.filterForm.validate((valid) => {
            needLoad = valid
          })
        }

        if (this.config.autoLoad || reset || (!this.config.autoLoad && needLoad)) {
          this.activeFieldsets = [...new Set(Object.keys(activeFieldsetKeys).concat(this.activeFieldsets))]
          if (hasFilters && this.filterCollapsed) {
            this.actionFilter()
            this.formReady = false
          }
          this.$nextTick(() => {
            this.actionApplyFilter(false, clearParams)
          })
        }
      })
    },

    legendName(fieldset) {
      if (!fieldset.legend) {
        return ''
      }
      return fieldset.translateLegend ? this.translate(`form.legends.${fieldset.legend}`) : fieldset.legend
    },

    attr(fieldName, field, fieldset) {
      if(!field) { field = this.fieldReference[fieldName] }
      if(!fieldset) { fieldset = this.fieldsets[field.fieldsetIndex] }

      return {
        field: field,
        fieldset: fieldset,
        formItemWraper: false,
        blockScope: 'filter'
      }
    }
  }
}
