export default {
  name: 'settings',
  items: [
    {
      name: 'source',
      endpoint: '/persistance/general/xrates',
      type: 'graphql',
      graphql: {
        name: 'xrateSource',
        queries: {
          index: {
            id: true,
            code: true,
            title: true,
            currency: true,
            url: true,
            encoding: true,
            missingCurrenciesSource: true,
            active: true,
            updatedAt: true,
            currencies: {
              id: true,
              isoCode: true,
              title: true,
              active: true
            }
          }
        },
        preloadFields: ['currencies{id isoCode}']
      },
      preload: true,
      preloadStores: ['currencies'],
      table: {
        items: [
          {
            name: 'id',
            width: '80px'
          },
          'code',
          'title',
          {
            name: 'currency',
            width: '80px'
          },
          'url',
          'encoding',
          'missingCurrenciesSource',
          {
            name: 'active',
            type: 'checkbox',
            width: '70px'
          },
          {
            name: 'updatedAt',
            width: '140px'
          },
          {
            name: 'actions',
            width: '100px',
            align: 'center'
          },
          'expand'
        ]
      },
      history: [
        'TtnXratesService::Source'
      ],
      actions: {
        row: [
          {
            name: 'currencies',
            icon: 'el-icon-plus',
            type: 'success',
            plain: true
          },
          {
            name: 'update',
            icon: 'el-icon-edit',
            type: 'primary',
            plain: true
          }
        ],
        panel: [
          {
            name: 'refresh',
            icon: 'el-icon-refresh',
            type: 'primary',
            plain: true
          },
          {
            name: 'history',
            icon: 'el-icon-refresh',
            type: 'warning',
            plain: true
          },
          {
            name: 'filter',
            context: 'panel',
            icon: 'el-icon-help',
            plain: true
          }
        ]
      },
      filter: {
        fieldsets: [
          {
            legend: 'filters',
            items: [
              {
                name: 'code',
                type: 'text',
                operator: 'eq',
                scope: 'sources'
              },
              {
                name: 'title',
                type: 'text',
                operator: 'like',
                scope: 'sources'
              },
              {
                name: 'currency',
                type: 'text',
                operator: 'eq',
                scope: 'sources'
              },
              {
                name: 'active',
                type: 'radiogroup',
                button: true,
                operator: 'eq',
                scope: 'sources',
                store: {
                  '': 'Any',
                  true: 'yes',
                  false: 'no'
                },
              }
            ]
          }
        ]
      },
      form: {
        actualize: true,
        fieldsets: [
          {
            legend: 'Default',
            items: [
              {
                name: 'id',
                type: 'hidden',
                required: false,
                valueType: 'integer'
              },
              {
                name: 'title',
                type: 'text'
              },
              {
                name: 'currency',
                type: 'text'
              },
              {
                name: 'url',
                type: 'text'
              },
              {
                name: 'encoding',
                type: 'text'
              },
              {
                name: 'missingCurrenciesSource',
                type: 'text'
              },
              {
                name: 'active',
                type: 'checkbox'
              }
            ]
          }
        ]
      },
      combined: [
        {
          name: 'source_currency_combined',
          type: 'graphql',
          graphql: {
            name: 'xrateSource',
            actions: {
              delete: 'xrateSourceDeleteCurrency'
            }
          },
          boot: {
            type: 'local',
            row_key: 'currencies',
          },
          table: {
            items: [
              'selection',
              {
                name: 'isoCode',
                width: '80px'
              },
              'title',
              {
                name: 'active',
                type: 'checkbox',
                width: '70px'
              },
              'expand'
            ]
          },
          actions: {
            row: [],
            panel: [
              {
                name: 'delete',
                icon: 'el-icon-delete',
                type: 'danger',
                plain: true,
                dependent: 'selection'
              },
              {
                name: 'create',
                icon: 'el-icon-edit',
                type: 'primary',
                plain: true
              }
            ]
          },
          form: {
            actualize: false,
            fieldsets: [
              {
                legend: 'Default',
                items: [
                  {
                    name: 'id',
                    type: 'hidden',
                    required: false,
                    valueType: 'integer'
                  },
                  {
                    name: 'currency',
                    type: 'select',
                    valueType: 'integer',
                    storeKey: 'currencies',
                    storePrimaryKey: 'id',
                    storeLabelKey: 'isoCode'
                  },
                ]
              }
            ]
          },

          combined: [
            {
              name: 'source_currency_combined_11',
              type: 'graphql',
              graphql: {
                name: 'xrateSource',
                actions: {
                  delete: 'xrateSourceDeleteCurrency'
                }
              },
              boot: {
                type: 'local',
                row_key: 'currencies',
              },
              table: {
                items: [
                  'selection',
                  {
                    name: 'isoCode',
                    width: '80px'
                  },
                  'title',
                  {
                    name: 'active',
                    type: 'checkbox',
                    width: '70px'
                  }
                ]
              },
              actions: {
                row: [],
                panel: [
                  {
                    name: 'delete',
                    icon: 'el-icon-delete',
                    type: 'danger',
                    plain: true,
                    dependent: 'selection'
                  },
                  {
                    name: 'create',
                    icon: 'el-icon-edit',
                    type: 'primary',
                    plain: true
                  }
                ]
              },
              form: {
                actualize: false,
                fieldsets: [
                  {
                    legend: 'Default',
                    items: [
                      {
                        name: 'id',
                        type: 'hidden',
                        required: false,
                        valueType: 'integer'
                      },
                      {
                        name: 'currency',
                        type: 'select',
                        valueType: 'integer',
                        storeKey: 'currencies',
                        storePrimaryKey: 'id',
                        storeLabelKey: 'isoCode'
                      },
                    ]
                  }
                ]
              },
            }
          ]
        }
      ]
    },

    {
      name: 'currency',
      endpoint: '/persistance/general/xrates',
      type: 'graphql',
      graphql: {
        name: 'xrateCurrency',
        queries: {
          index: {
            id: true,
            isoCode: true,
            title: true,
            active: true,
            updatedAt: true
          }
        },
        actions: {
          index: 'xrateCurrencys'
        },
      },
      table: {
        items: [
          {
            name: 'id',
            width: '80px'
          },
          'isoCode',
          'title',
          {
            name: 'active',
            type: 'checkbox',
            width: '70px'
          },
          {
            name: 'updatedAt',
            width: '140px'
          },
          {
            name: 'actions',
            width: '70px',
            align: 'center'
          }
        ]
      },
      history: [
        'TtnXratesService::Currency'
      ],
      actions: {
        row: [
          {
            name: 'update',
            icon: 'el-icon-edit',
            type: 'primary',
            plain: true
          }
        ],
        panel: [
          {
            name: 'refresh',
            icon: 'el-icon-refresh',
            type: 'primary',
            plain: true
          },
          {
            name: 'history',
            icon: 'el-icon-refresh',
            type: 'warning',
            plain: true
          },
          {
            name: 'filter',
            context: 'panel',
            icon: 'el-icon-help',
            plain: true
          }
        ]
      },
      filter: {
        fieldsets: [
          {
            legend: 'filters',
            items: [
              {
                name: 'iso_code',
                type: 'text',
                operator: 'eq',
                scope: 'currencies'
              },
              {
                name: 'title',
                type: 'text',
                operator: 'like',
                scope: 'currencies'
              },
              {
                name: 'active',
                type: 'radiogroup',
                button: true,
                operator: 'eq',
                scope: 'currencies',
                store: {
                  '': 'Any',
                  true: 'yes',
                  false: 'no'
                },
              }
            ]
          }
        ]
      },
      form: {
        actualize: true,
        fieldsets: [
          {
            legend: 'Default',
            items: [
              {
                name: 'id',
                type: 'hidden',
                required: false,
                valueType: 'integer'
              },
              {
                name: 'isoCode',
                type: 'text'
              },
              {
                name: 'title',
                type: 'text'
              },
              {
                name: 'active',
                type: 'checkbox'
              }
            ]
          }
        ]
      }
    }

  ]
}