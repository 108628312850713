import pluralize from 'pluralize'
import combined from './view/combined.vue'
import Vue from 'vue'
import lodash from 'lodash'

const defaultActions = [
  {
    name: 'refresh',
    icon: 'el-icon-refresh',
    type: 'primary',
    plain: true
  },
  {
    name: 'filter',
    icon: 'el-icon-help',
    plain: true
  }
]
const actionRoleMapper = {
  create: 'edit',
  update: 'edit',
  delete: 'edit',
  refresh: false,
  filter: false,
  history: 'history'
}

const upcase = (str) => {
  return str[0].toUpperCase() + str.slice(1)
}

const strToCamelCase = (str) => {
  const arr = str.split(/[_-]/)
  let newStr = ''
  for (let i = 1; i < arr.length; i++) {
    newStr += arr[i].charAt(0).toUpperCase() + arr[i].slice(1)
  }
  return arr[0] + newStr
}

export { strToCamelCase }

const prepareFormItem = (i, itemType) => {
  const itm = typeof i === 'string' ? { name: i } : i
  if (itemType === 'graphql') {
    if(itm.name.indexOf('__')) {
      // for nested forms camelize names
      itm.name = lodash.map(itm.name.split('__'), (n) => { return strToCamelCase(n) }).join('__')
    } else{
      itm.name = strToCamelCase(itm.name)
    }
  }
  return Object.assign({
    name: '',
    type: 'text'
  }, itm)
}

const prepareFilterItem = (i, fieldsetScope) => {
  const itm = typeof i === 'string' ? { name: i } : i
  itm.camelizeLangKey = itm.camelizeLangKey !== false
  itm.labelKey = itm.labelKey || (itm.camelizeLangKey ? strToCamelCase(itm.name) : null)
  itm.scope = itm.scope || fieldsetScope

  return Object.assign({
    name: '',
    type: 'text'
  }, itm)
}

const _prepareActions = (i) => {
  const actions = { panel: [], row: [], searchBy: [] }
  let hasForm = false
  const hasFilter = i.filter &&
    (
      Array.isArray(i.filter.fieldsets) && i.filter.fieldsets.length > 0 ||
      Array.isArray(i.filter.items) && i.filter.items.length > 0
    )
  const history = []
  const permissions = {}
  if (i.actions) {
    for (const scope of Object.keys(actions)) {
      if (i.actions[scope]) {
        for (let n = 0; n < i.actions[scope].length; n++) {
          hasForm = hasForm || ['create', 'update'].includes(i.actions[scope][n].name)
          const action = Object.assign({
            name: '',
            icon: null,
            type: 'primary',
            plain: true,
            roles: []
          }, i.actions[scope][n])
          action.methodName = strToCamelCase(`action_${action.name}`)
          if (action.name === 'filter' && !hasFilter) {
            continue
          }
          if (action.name === 'history') {
            history.push(action.params)
          }
          if (action.roles === false) {
            action.roles = []
          } else {
            if (i.meta && i.meta.role_name) {
              if (action.name in actionRoleMapper) {
                if (actionRoleMapper[action.name]) {
                  action.roles.push(`${i.meta.role_name}.${actionRoleMapper[action.name]}`)
                }
              } else {
                action.roles.push(`${i.meta.role_name}.${action.name}`)
              }
            }
          }
          for (let pr in action.roles) {
            permissions[action.roles[pr]] = permissions[action.roles[pr]] || Object.freeze({
              key: action.roles[pr],
              type: 'action'
            })
          }
          actions[scope].push(action)
        }
      } else {
        actions[scope] = []
      }
    }
  } else {
    if (!i.isCombined) {
      actions.panel = defaultActions
    }
  }
  return {
    actions,
    hasForm,
    history,
    permissions: Object.values(permissions)
  }
}

const _prepareTable = (i, itemType) => {
  const table = {
    items: [],
    hideHeader: false,
    expandRowClass: null,
    defaultSort: {
      column: null,
      dir: null
    },
    sortType: 'remote',
    class: ''
  }
  if (i.table) {
    table.hideHeader = i.table.hideHeader || table.hideHeader
    table.expandRowClass = i.table.expandRowClass || table.expandRowClass
    table.class = i.table.class || table.class
    table.defaultSort = Object.freeze(Object.assign(table.defaultSort, i.table.defaultSort || {}))
    table.sortType = i.table.sortType || table.sortType

    if (Array.isArray(i.table.items)) {
      for (let n = 0; n < i.table.items.length; n++) {
        const itm = typeof i.table.items[n] === 'string' ? { name: i.table.items[n] } : i.table.items[n]
        if (itemType === 'graphql') {
          itm.name = strToCamelCase(itm.name)
        }
        table.items.push(Object.assign({
          name: '',
          width: null,
          align: null,
          type: 'text',
          beforeRender: null,
          format: null,
          sortable: true
        }, itm))
      }
    }
  }
  return table
}

const _prepareFilter = (i) => {
  const filter = {
    collapsed: false,
    class: '',
    position: 'right',
    labelPosition: 'top',
    labelWidth: '120px',
    statusIcon: false,
    fieldsets: []
  }
  if (i.filter) {
    filter.collapsed = i.filter.collapsed || false
    filter.class = i.filter.class || filter.class
    filter.position = ['top', 'right'].includes(i.filter.position) ? i.filter.position : 'right'
    filter.labelPosition = i.filter.labelPosition ? i.filter.labelPosition : (filter.position === 'top' ? 'left' : filter.labelPosition)
    filter.labelWidth = i.filter.labelWidth ? i.filter.labelWidth : (filter.position === 'top' ? '150px' : filter.labelWidth)
    filter.statusIcon = i.filter.statusIcon !== undefined ? i.filter.statusIcon : false
    if (Array.isArray(i.filter.fieldsets)) {
      for (let s = 0; s < i.filter.fieldsets.length; s++) {
        const fieldset = {
          legend: i.filter.fieldsets[s].legend,
          translateLegend: i.filter.fieldsets[s].translateLegend !== false,
          collapsed: i.filter.fieldsets[s].collapsed !== undefined ? i.filter.fieldsets[s].collapsed : false,
          collapsible: i.filter.fieldsets[s].collapsible !== undefined ? i.filter.fieldsets[s].collapsible : true,
          items: []
        }
        const fieldsetScope = i.filter.fieldsets[s].scope
        for (let n = 0; n < i.filter.fieldsets[s].items.length; n++) {
          fieldset.items.push(prepareFilterItem(i.filter.fieldsets[s].items[n], fieldsetScope))
        }
        filter.fieldsets.push(fieldset)
      }
    } else {
      if (Array.isArray(i.filter.items) && i.filter.items.length > 0) {
        const fieldset = {
          legend: null,
          translateLegend: true,
          collapsed: false,
          items: []
        }
        for (let n = 0; n < i.filter.items.length; n++) {
          fieldset.items.push(prepareFilterItem(i.filter.items[n], null))
        }
        filter.fieldsets.push(fieldset)
      }
    }
  }
  return filter
}

const _prepareForm = (i, itemType) => {
  const form = {
    actualize: (i.form ? i.form.actualize : false),
    closeOnClickModal: (i.form ? i.form.closeOnClickModal === true : false),
    labelPosition: (i.form && i.form.labelPosition ? i.form.labelPosition : 'left'),
    labelWidth: (i.form && i.form.labelWidth ? i.form.labelWidth : '150px'),
    statusIcon: (i.form && i.form.statusIcon !== undefined ? i.form.statusIcon : true),
    fieldsets: [],
    windowWidth: (i.form && i.form.windowWidth ? i.form.windowWidth : null),
    fullscreen: (i.form ? i.form.fullscreen : false)
  }
  if (i.form) {
    if (Array.isArray(i.form.fieldsets)) {
      for (let s = 0; s < i.form.fieldsets.length; s++) {
        const fieldset = {
          legend: i.form.fieldsets[s].legend,
          translateLegend: (i.form.fieldsets[s].translateLegend || false),
          items: []
        }
        for (let n = 0; n < i.form.fieldsets[s].items.length; n++) {
          fieldset.items.push(prepareFormItem(i.form.fieldsets[s].items[n], itemType))
        }
        form.fieldsets.push(fieldset)
      }
    } else {
      if (Array.isArray(i.form.items)) {
        const fieldset = {
          legend: null,
          translateLegend: false,
          items: []
        }
        for (let n = 0; n < i.form.items.length; n++) {
          fieldset.items.push(prepareFormItem(i.form.items[n], itemType))
        }

        form.fieldsets.push(fieldset)
      }
    }
  }

  return form
}

const _prepareHistory = (i, oldFormat) => {
  const history = []
  let temp = oldFormat
  if (Array.isArray(i.history)) {
    temp = i.history
  }
  for (let n = 0; n < temp.length; n++) {
    let tmp = {
      record_class: (typeof temp[n] === 'string' ? temp[n] : temp[n].record_class),
      name: i.name,
      namespace: i.namespace,
      component: i.component,
      tab: i.tab
    }
    history.push(Object.freeze(tmp))
  }
  return history
}

const _prepareDependentItems = (i) => {
  const result = []
  if (!Array.isArray(i.dependentItems)) {
    return result
  }
  for (let n = 0; n < i.dependentItems.length; n++) {
    const itm = typeof i.dependentItems[n] === 'string' ? { name: i.dependentItems[n] } : i.dependentItems[n]
    itm.namespace = itm.namespace || i.namespace
    itm.component = itm.component || i.component
    itm.tab = itm.tab || i.tab
    result.push(Object.freeze(itm))
  }
  return result
}

const _preparePreloadStores = (i) => {
  const result = []
  if (!Array.isArray(i.preloadStores)) {
    return result
  }
  for (let n = 0; n < i.preloadStores.length; n++) {
    const itm = typeof i.preloadStores[n] === 'string' ? { name: i.preloadStores[n] } : i.preloadStores[n]
    if (!itm.name) {
      continue
    }
    itm.storePrimaryKey = itm.storePrimaryKey || 'id'
    result.push(Object.freeze(itm))
  }
  return result
}

const _prepareLayouts = (i) => {
  const result = {
    panel: null,
    table: null,
    panel_bottom: null,
    filter: null,
    form: null,
    history: null
  }
  if (!i.layouts) {
    return result
  }
  for (const type of Object.keys(result)) {
    if (i.layouts[type]) {
      result[type] = i.layouts[type]
    }
  }
  return result
}

const normalizeItem = (i, registerComponent) => {
  const itemType = (i.type || 'http')

  const actions = _prepareActions(i)

  const item = {
    namespace: i.namespace,
    component: i.component,
    tab: i.tab,
    name: i.name,
    layouts: _prepareLayouts(i),
    lazy: i.lazy || false,
    meta: Object.assign({ roles: [], permissions: [] }, i.meta || {}),
    disabled: i.disabled || false,
    autoLoad: (i.autoLoad !== undefined ? i.autoLoad : true),
    pagination: (i.pagination !== undefined ? i.pagination : true),
    paginationType: (i.paginationType !== undefined ? i.paginationType : 'limit'),
    paginationSizes: (i.paginationSizes !== undefined ? i.paginationSizes : [10, 25, 30, 50]),
    paginationSize: (i.paginationSize !== undefined ? i.paginationSize : 25),
    endpoint: i.endpoint,
    preload: (i.preload || false),
    preloadStores: _preparePreloadStores(i),
    dependentItems: _prepareDependentItems(i),
    type: itemType,
    hasForm: actions.hasForm,
    actions: actions.actions,
    table: _prepareTable(i, itemType),
    filter: _prepareFilter(i),
    form: _prepareForm(i, itemType),
    stores: i.stores || {},
    combined: i.combined || [],
    isCombined: false,
    history: _prepareHistory(i, actions.history),
    collapsibleCombine: (i.collapsibleCombine == true),
    combineParams: {
      class: (i.combineParams ? i.combineParams.class : ''),
      expanded: (i.combineParams ? !(i.combineParams.expanded == false) : true)
    }
  }

  if (item.combined.length > 0) {
    for(let i in item.combined) {
      item.combined[i].endpoint = item.endpoint
      item.combined[i].namespace = item.namespace
      item.combined[i].component = item.component
      item.combined[i].tab = item.tab
      item.combined[i].isCombined = true
      const ic = normalizeItem(item.combined[i])
      ic.parentName = item.name
      ic.isCombined = true
      ic.boot = {
        type: item.combined[i].boot ? (item.combined[i].boot.type || 'local') : 'local',
        row_key: item.combined[i].boot ? (item.combined[i].boot.row_key || 'data') : 'data',
        relation: item.combined[i].boot ? (item.combined[i].boot.relation || {}) : {},
        filters: item.combined[i].boot ? (item.combined[i].boot.filters || []) : [],
        filters_mapping: item.combined[i].boot ? item.combined[i].boot.filters_mapping : false,
        parent_filters: item.combined[i].boot ? item.combined[i].boot.parent_filters : false,
      }
      item.history = item.history.concat(ic.history)
      item.combined[i] = ic
    }
  }

  item.meta.permissions = item.meta.permissions.concat(actions.permissions)

  if (item.type === 'graphql') {
    const historyFields = ['time', 'recordId', 'operation', 'userLogin', 'changes']
    item.graphql = {
      name: (i.graphql ? i.graphql.name : item.name),
      exclude: (i.graphql ? i.graphql.exclude || [] : []),
      preloadFields: (i.graphql ? i.graphql.preloadFields || [] : []),
      historyFields: (i.graphql ? i.graphql.historyFields || historyFields : []),
      baseParams: (i.graphql ? i.graphql.baseParams || {} : {})
    }

    item.graphql.actions = Object.assign({
      index: pluralize.plural(item.graphql.name),
      get: pluralize.singular(item.graphql.name),
      create: `create${upcase(pluralize.singular(item.graphql.name))}`,
      update: `update${upcase(pluralize.singular(item.graphql.name))}`,
      delete: `delete${upcase(pluralize.singular(item.graphql.name))}`,
      preload: `${pluralize.singular(item.graphql.name)}Preload`
    }, (i.graphql ? i.graphql.actions || {} : {}))

    item.graphql.queries = Object.assign({}, (i.graphql ? i.graphql.queries || {} : {}))
  }
  return item
}

export { normalizeItem }

export function normalizeChild(c) {
  const meta = Object.assign({
    roles: [],
    role_name: '',
    permissions: {},
    title: 'default',
    icon: null,
    noCache: false,
    breadcrumb: true
  }, (c.meta || {}))

  if (!Array.isArray(c.tabs)) {
    return false
  }
  meta.role_name = `${[c.namespace, c.component].filter(e => !!e).join('.')}`
  meta.roles.push(meta.role_name)
  const tabs = []
  for(let t = 0; t < c.tabs.length; t++) {
    const tab = Object.assign({}, c.tabs[t])
    tab.namespace = c.namespace
    tab.component = c.component
    tab.meta = Object.assign({ roles: [], permissions: {}, noCache: false }, tab.meta || {})
    tab.meta.role_name = `${[c.namespace, c.component, tab.name].filter(e => !!e).join('.')}`
    tab.meta.roles.push(tab.meta.role_name)
    const items = []
    for (let i = 0; i < tab.items.length; i++) {
      tab.items[i].namespace = c.namespace
      tab.items[i].component = c.component
      tab.items[i].meta = Object.assign({ roles: [], role_name: '', permissions: [], noCache: false }, tab.items[i].meta || {})
      tab.items[i].meta.role_name = [tab.meta.role_name, tab.items[i].name].join('.')
      tab.items[i].meta.roles.push(`${tab.items[i].meta.role_name}.view`)
      tab.items[i].tab = tab.name
      if (tab.lazy !== undefined) {
        tab.items[i].lazy = tab.lazy
      }
      let item = normalizeItem(tab.items[i])
      item.meta.permissions = Object.freeze({
        key: `${tab.items[i].meta.role_name}.view`,
        type: 'item',
        items: item.meta.permissions
      })
      items.push(item)
    }
    tab.items = items
    tab.meta.permissions = Object.freeze({
      key: tab.meta.role_name,
      type: 'tab',
      items: items.reduce((acc, i) => {
        acc.push(i.meta.permissions)
        return acc
      }, [])
    })
    tabs.push(tab)
  }
  meta.permissions = Object.freeze({
    key: meta.role_name,
    type: 'menu',
    items: tabs.reduce((acc, i) => {
      acc.push(i.meta.permissions)
      return acc
    }, [])
  })
  return {
    component: c.component,
    meta: meta,
    tabs: tabs,
    namespace: c.namespace
  }
}

export function normalizeModule(module) {
  const children = []
  for(let ci in module.children) {
    if (module.children[ci].children) {
      const c2 = normalizeModule(module.children[ci])
      if (c2) {
        children.push(c2)
      }
    } else {
      const c1 = normalizeChild(module.children[ci])
      if (c1) {
        children.push(c1)
      }
    }
  }
  module.children = children
  module.meta = Object.assign({
    roles: [],
    permissions: {},
    title: 'default',
    icon: null,
    noCache: false,
    breadcrumb: true
  }, (module.meta || {}))
  module.meta.roles.push(module.name)
  module.meta.permissions = Object.freeze({
    key: module.name,
    type: 'menu',
    items: children.reduce((acc, i) => {
      acc.push(i.meta.permissions)
      return acc
    }, [])
  })
  return module
}

export function registerComponent(i) {
  const comp = {
    computed: combined.computed,
    methods: combined.methods,
    mixins: combined.mixins,
    name: 'CrudCTab',
    render: combined.render,
    inject: ['$elItem'],
    provide() {
      return {
        '$elItem': this,
        '$elTab': this.$elItem,
        '$elRootItem': this.$elRootItem
      }
    }
  }
  comp.props = {
    config: {
      required: false,
      type: Object,
      default: function() {
        return i
      }
    },
    records: {
      required: true,
      type: Array,
      default: () => {
        return []
      }
    },
    row: {
      required: false,
      type: Object,
      default: () => {
        return {}
      }
    },
    parentFilters: {
      required: false,
      type: Object,
      default: () => {
        return {}
      }
    }
  }

  Vue.component(i.name, comp)
  return true
}
