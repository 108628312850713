import source from './xrates/settings/source'
import rate_base from './xrates/rate/rate_base'
import history_base from './xrates/rate/history_base'

var drivers = [
  'privat', 'cbr', 'nbu', 'bank_ua', 'cbar', 'nbp', 'bnm', 'kazahstan', 'turkish', 'nbr',
  'amadeus_ua', 'amadeus_ua_eur', 'amadeus_ru', 'amadeus_de', 'amadeus_us', 'amadeus_direct_usd', 'amadeus_direct_eur',
  'banxico', 'nbkr', 'cba', 'cbi', 'nbz', 'mastercard', 'nbg', 'nab', 'bankofcanada', 'snb', 'boi', 'riksbank', 'boc',
  'cbk', 'cbn', 'sabre_ua', 'sabre_ru', 'sabre_de', 'sabre_us', 'google_usd'
]
var lang = {
  xrates: {
    settings: {
      settings: source
    },
    rates: {}
  }
}

for (let i in drivers) {
  let driver = drivers[i]
  lang.xrates.rates[driver] = {}
  lang.xrates.rates[driver][`rate_${driver}`] = rate_base
  lang.xrates.rates[driver][`rate_history_${driver}`] = history_base
  lang.xrates.rates[driver][`rate_history_combined_${driver}`] = history_base
}

export default lang
