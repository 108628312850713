export default {
  source: {
    table:{
      code: 'Code',
      title: 'Title',
      currency: 'Currency',
      url: 'Url',
      encoding: 'Encoding',
      missingCurrenciesSource: 'Missing source',
      active: 'Active'
    },
    form: {
      fields: {
        code: 'Code',
        title: 'Title',
        currency: 'Currency',
        url: 'Url',
        encoding: 'Encoding',
        missingCurrenciesSource: 'Missing source',
        active: 'Active'
      },
      legends: {
        filters: 'Filters'
      }
    },
    modal: {
      add_currency: 'Add currency'
    }
  },
  source_currency_combined: {
    table: {
      active: 'Active',
      isoCode: 'Code'
    },
    form: {
      fields: {
        currency: 'Currency'
      }
    }
  },
  currency: {
    table: {
      isoCode: 'Code',
      active: 'Active'
    },
    form: {
      fields: {
        isoCode: 'Code',
        title: 'Title',
        active: 'Active'
      },
      legends: {
        filters: 'Filters'
      }
    }
  }
}
