import tabs from './rate/index'

export default {
  namespace: 'xrates',
  component: 'rates',
  meta: {
    title: 'xrates.rates',
    icon: 'table'
  },
  tabs: tabs
}
