<template lang="pug">
  extends /table
</template>

<script>
import { jsonToGraphQLQuery } from 'json-to-graphql-query'
import Base from '@crud_combined/table'

export default {
  extends: Base,
  methods: {
    _deleteGraphqlParams() {
      const query = {
        mutation: {}
      }
      query.mutation[this.config.graphql.actions.delete] = { }
      query.mutation[this.config.graphql.actions.delete]['__args'] = {
        ids: this.multipleSelection.map((i) => { return (i.id.toString().includes('-') ? i.id.toString() : parseInt(i.id)) }),
        sourceId: parseInt(this.row.id)
      }
      return { query: `${jsonToGraphQLQuery(query)}` }
    }
  }
}
</script>

