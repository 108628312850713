<template lang="pug">
  extends /panel
  block right_prepend
    el-select(v-model="fastNavigation" @change="navigateToTab" placeholder="Fast navigation" filterable)
      el-option(
        v-for="driver in config.stores.drivers"
        :label="driver"
        :value="driver"
        :key="driver"
      )
    span &nbsp;
</template>

<script>
import BasePanel from '@crud_view/panel'
import common from '@crud_lib/common'
import di from '@crud_lib/di'

export default {
  extends: BasePanel,
  mixins: [common, di],
  data() {
    return {
      fastNavigation: null
    }
  },
  methods: {
    navigateToTab(value) {
      this.$elContainer.$emit(this.$channels.navigationTab, {
        tab: value,
        item: `rate_${value}`,
        filters: {
          sources: [
            {
              field: 'code', operator: 'eq', value: value, prefilled: true
            }
          ]
        }
      })
    },
  }
}
</script>
