export default {
  table:{
    sourceName: 'Driver',
    rate: 'Rate',
    sourceRate: 'Source rate',
    deviation: 'Deviation',
    crossRate: 'Cross rate',
    locked: 'Locked',
    currency: 'Currency',
    sourceCurrency: 'Base currency'
  },
  form: {
    fields: {
      currency: 'Currency',
      locked: 'Locked',
      rate: 'Rate'
    },
    legends: {
      default: 'Filters'
    }
  }
}
